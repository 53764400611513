@mixin colors ($background, $hover, $focus) {
	background: $background;
	&:hover {
		background: $hover;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem $focus;
	}
}

.container {
	cursor: pointer;
	@include colors(#007bff, #0069d9, rgba(0, 123, 255, 0.5));
	&[custom-danger=true] {
		@include colors(#dc3545, #c82333, rgba(220, 53, 69, 0.5));
	}
	&[custom-warning=true] {
		@include colors(#ffc107, #d39e00, rgba(255, 193, 7, 0.5));
		color: black;
	}
	&[custom-success=true] {
		@include colors(#28a745, #218838, rgba(40, 167, 69, 0.5));
	}
	&[custom-disabled=true] {
		background: gray;
		cursor: default;
		&:hover {
			background: gray;
		}
		&:focus {
			box-shadow: none;
		}
	}
	color: white;
	padding: 6px 12px;
	display: inline-block;
	margin: 5px;
	border-radius: .25rem;
	user-select: none;
	transition:
		background-color .15s ease-in-out,
		box-shadow .15s ease-in-out;
	text-decoration: none;
}
