.container {
	border: 1px solid gray;
	border-radius: 5px;
	margin: 5px;
	padding: 5px;
	&:focus {
		outline: none;
		box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
	}
}
