@keyframes appear {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.container {
	overflow-y: hidden;
	box-shadow: 0 2px 3px #0002;
	user-select: none;
	background-color: #222;
	display: flex;
	flex-direction: column;
	.searchBar {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		input {
			background-color: #fff8;
			border-radius: 6px;
			overflow: hidden;
			margin: 12px 6px 12px 6px;
			transition: background-color ease-in-out 0.2s;
			&:focus-within, &[custom-text-inside="true"] {
				background-color: #fff;
			}
			border: none;
			outline: none;
			font-size: 16px;
			padding: 10px;
		}
		.clearIcon {
			padding-left: 8px;
			padding-right: 8px;
			color: white;
			cursor: pointer;
			animation-name: appear;
			animation-duration: 0.8s;
			animation-timing-function: ease-out;
		}
	}
	.content {
		overflow-y: auto;
		color: white;
		.message {
			text-align: center;
			opacity: .5;
		}
		.item {
			display: flex;
			justify-content: left;
			align-items: center;
			padding: 8px;
			overflow: hidden;
			cursor: pointer;
			border: 1px solid transparent;
			&:hover:not([custom-selected="true"]) {
				border: 1px solid #fff2;
			}
			&[custom-selected="true"] {
				cursor: default;
				background-color: #000a;
				font-weight: 700;
			}
		}
	}
	.new {
		margin-top: auto;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		input {
			border: none;
			outline: none;
			font-size: 1rem;
			margin: 5px;
			padding: 10px;
			background-color: #fff8;
			border-radius: 6px;
			overflow: hidden;
			transition: background-color ease-in-out 0.2s;
			&:focus-within, &[custom-text-inside="true"] {
				background-color: #fff;
			}
		}
	}
}
