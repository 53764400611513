.container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: grid;
	grid-template-rows: auto 1fr;
	grid-template-columns: 1fr;
	& > * {
		min-width: 0;
		min-height: 0;
		overflow: auto;
	}
}
