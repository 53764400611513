.container {
	display: grid;
	grid-template-rows: auto 1fr;
}

.tabs {
	background-color: white;
	display: flex;
	user-select: none;
	overflow-y: auto;
	& > div {
		padding: 5px;
		margin-right: 1rem;
		cursor: pointer;
		&[custom-selected=true] {
			cursor: default;
			background-color: lightgray;
			font-weight: bold;
		}
	}
}
