.container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	.card {
		border-radius: 4px;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto 1fr auto;
		box-shadow: 0 0 10px #0002;
		min-width: 340px;
		overflow: hidden;
		& > * {
			padding-left: 10px;
			padding-right: 10px;
			background-color: white;
		}
		.title {
			background-color: #0004;
			color: white;
		}
		.inputs {
			display: flex;
			flex-direction: column;
			gap: 10px;
			padding-top: 28px;
			& input {
				font-size: 18px;
			}
		}
		.bottom {
			padding-top: 28px;
			display: flex;
			justify-content: right;
			.loginButton {
				text-transform: uppercase;
				font-weight: 600;
				letter-spacing: -0.2px;
				user-select: none;
				cursor: pointer;
				padding: 8px;
				border-radius: 2px;
				&:hover {
					background-color: #00000008;
				}
			}
		}
	}
}
