.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	& > div {
		background-color: white;
		border-radius: 5px;
		font-size: 1.3rem;
		padding: 1rem;
		margin: 1rem;
	}
}
