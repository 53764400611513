.container {
	display: flex;
	flex-direction: column;
}

.crash {
	background-color: rgb(255, 182, 182);
	color: red;
	font-weight: bold;
	border: 1px solid red;
	border-radius: 5px;
	margin: 1rem;
	padding: 1rem;
	.date {

	}
	.browser {

	}
	.os {

	}
	.web {

	}
	.message {

	}
}
