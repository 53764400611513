$deg: 5deg;

@keyframes glingling {
	0% {
		transform: rotate(-$deg);
	}
	100% {
		transform: rotate($deg);
	}
}

.container {
	display: flex;
	justify-content: center;
	align-items: center;
	.card {
		width: 300px;
		height: 200px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: white;
		border-radius: 6px;
		box-shadow: 1px 2px 4px #0004;
		animation-name: glingling;
		animation-duration: 1.5s;
		animation-iteration-count: infinite;
		animation-timing-function: ease-in-out;
		animation-direction: alternate;
		word-break: break-all;
		padding: 8px;
		overflow-x: hidden;
		overflow-y: auto;
		font-size: 28px;
		font-weight: bold;
	}
}