.container {
	background-color: #222;
	color: white;
	display: grid;
	grid-template-columns: 1fr auto;
	padding: 5px;
	box-shadow: 0 2px 3px #0002;
	z-index: 100;
	.buttons {
		display: flex;
		gap: 20px;
		padding-left: 10px;
		padding-right: 10px;
	}
}
