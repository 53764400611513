.container {
	overflow-y: auto;
	.phrase {
		background-color: white;
		padding: 1.5rem;
		margin: 1rem;
		border-radius: 5px;
		display: flex;
		justify-content: space-around;
		& > div {
			display: inline;
			width: 100%;
			text-align: center;
			& > input {
				width: 100%;
			}
		}
	}
}

.input {
	margin: 1rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	input {
		flex: 1;
	}
}
