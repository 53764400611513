.container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
}

.input {
	margin: 1rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	input {
		flex: 1;
	}
}
