@keyframes loading {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}

.container {
	cursor: pointer;
	text-align: center;
	user-select: none;
	&[custom-loading=true] {
		cursor: default;
		opacity: 0.75;
		.icon {
			animation-name: loading;
			animation-timing-function: cubic-bezier(.87,-0.47,.27,1.35);
			animation-duration: 2s;
			animation-iteration-count: infinite;
			animation-direction: normal;
		}
	}
	color: #929292;
	&[custom-selected=true] {
		color: white;
	}
	.icon {
		font-size: 32px;
	}
	.label {
		font-size: 14px;
	}
	min-width: 3rem;
}
