.container {
	display: flex;
	flex-direction: row;
	overflow-y: hidden;
	width: 100vw;
}

.content {
	overflow-y: auto;
	flex: 1;
}
