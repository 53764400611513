.container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
}

.input {
	margin: 1rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	input {
		flex: 1;
	}
}

.category {
	background-color: white;
	padding: 1rem;
	border-radius: 6px;
	margin: 1rem;
}

.description {
	border: 1px solid gray;
	border-radius: 5px;
	margin: 1rem;
	padding: 5px;
	&:focus {
		outline: none;
		box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
	}
}
