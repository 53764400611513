.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 1rem;
	padding: 1rem;
	background-color: white;
	border-radius: 6px;
	box-shadow: 0 2px 3px #0002;
}

.fields {
	background-color: white;
	border-radius: 7px;
	margin: 1rem;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	& > div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: stretch;
		width: 100%;
	}
}

.input {
	margin: 0.5rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	input {
		flex: 1;
	}
}

.role {
	display: flex;
	flex-direction: row;
}

.info {
	display: flex;
	flex-direction: column;
}

.title {
	font-weight: bold;
}

.description {
	font-style: italic;
}
